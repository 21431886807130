import React, { useState } from 'react'

import HowdyLogo from '../Icons/HowdyLogo'
import ListIcon from '../Icons/ListIcon'
import CloseIcon from '../Icons/CloseIcon'
import SectionsBlock from './SectionsBlock'
import { Section } from './types'
import SignOut from '../Icons/SignOut'
import { useAuthCommon } from '../../../../contexts/authCommonContext'

const NavigationSideBarMobile = ({
  menuSections,
  news,
}: {
  menuSections: Section[]
  news: any
}) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const { isAuthenticated, logOut } = useAuthCommon()

  const toggleIsNavExpanded = () => {
    setIsNavExpanded(!isNavExpanded)
  }
  const closeNavBar = () => {
    setIsNavExpanded(false)
  }

  return (
    <nav className='relative max-h-[80vh] border-b-2 border-black bg-white shadow-xl md:py-4'>
      <div className='flex justify-between px-5 py-4' onClick={toggleIsNavExpanded}>
        <HowdyLogo className='h-[44px] w-[117px]' />
        {isNavExpanded ? (
          <CloseIcon color='#449386' />
        ) : (
          <div className='relative'>
            <ListIcon color='#449386' />
            {Object.values(news).some((v) => v) && (
              <div className='absolute right-0 top-0 z-10 h-[12px] w-[12px] rounded-full bg-[#ED4F00]' />
            )}
          </div>
        )}
      </div>

      {isNavExpanded && (
        <div className='absolute left-0 max-h-[calc(100vh-92px)] w-full overflow-y-auto border-b-2 border-black bg-white px-5 py-10 shadow-xl'>
          <SectionsBlock
            sections={menuSections}
            isNavExpanded
            closeNavBar={closeNavBar}
            news={news}
          />
          {isAuthenticated && (
            <span className='mt-[70px] flex cursor-pointer text-sm font-bold' onClick={logOut}>
              <SignOut />
              <span className='ml-4'>Log out</span>
            </span>
          )}
        </div>
      )}
    </nav>
  )
}

export default NavigationSideBarMobile
