import React, { useState } from 'react'
import HowdyLogo from '../Icons/HowdyLogo'
import HowdyLogoInitial from '../Icons/HowdyLogoInitial'
import SignOut from '../Icons/SignOut'
import clsx from 'clsx'
import { Section } from './types'
import SectionsBlock from './SectionsBlock'
import Button from '../Button'
import { useAuthCommon } from '../../../../contexts/authCommonContext'

const canViewPendingRecommendations =
  process.env.NEXT_PUBLIC_VIEW_PENDING_RECOMMENDATIONS === 'true'

const NavigationSideBarDesktop = ({
  menuSections,
  news,
}: {
  menuSections: Section[]
  news: any
}) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const { isAuthenticated, logOut } = useAuthCommon()

  const toggleIsNavExpanded = (value: boolean) => {
    setIsNavExpanded(value)
  }

  return (
    <nav
      className={clsx(
        'relative flex h-full flex-col justify-between bg-white pb-3 pl-3 pr-4 pt-11 shadow-2xl transition-all duration-75',
        {
          'w-52': isNavExpanded && !canViewPendingRecommendations,
          'w-70': isNavExpanded && canViewPendingRecommendations,
          'w-16': !isNavExpanded,
        },
      )}
      onMouseOver={() => toggleIsNavExpanded(true)}
      onMouseLeave={() => toggleIsNavExpanded(false)}
    >
      <div>
        <div className='ml-2'>
          {isNavExpanded ? <HowdyLogo className='h-[34px] w-[91px]' /> : <HowdyLogoInitial />}
        </div>
        <div
          className={clsx('mt-9 max-h-[80vh] p-[-3px]', {
            'overflow-y-auto': isNavExpanded,
          })}
        >
          <SectionsBlock sections={menuSections} isNavExpanded={isNavExpanded} news={news} />
        </div>
      </div>
      {isAuthenticated && (
        <span className='flex cursor-pointer items-center gap-2 text-sm font-bold' onClick={logOut}>
          {isNavExpanded && <Button style={'tertiary'}>Log out</Button>}{' '}
          <div className='text-howdy-primary-green'>
            <SignOut />
          </div>
        </span>
      )}
    </nav>
  )
}

export default NavigationSideBarDesktop
