import { useState } from 'react'
import axios from 'axios'
import PhoneForm from './phoneForm'
import CodeForm from './codeForm'
import { useUser } from '../../contexts/userContext'
import setUser from '../../actions/setUser'
import { errorToast, successToast } from 'packages/core/lib/toast'

const PhoneVerificationForm = ({ handleBack, handleSucces, phoneNumber }) => {
  const [phone, setPhone] = useState('')
  const { dispatch, userState: user } = useUser()

  const sendVerificationCode = async (phoneNumber) => {
    setPhone(phoneNumber)
    try {
      await axios.post('/phone/sendCode', {
        phoneNumber: phoneNumber,
      })
      successToast({
        title: 'Verification code sent',
        message: 'The verification code was succesfully sent to your phone',
      })
    } catch (e) {
      console.error('Error sending the code', e)
      errorToast({
        title: 'Error sending the verification',
        message: 'There was a error sending the verification',
      })
    }
  }
  const validateVerificationCode = async (code) => {
    try {
      await axios.post('/phone/verifyCode', {
        phoneNumber: phone,
        code: code,
        userId: user?.data?.id,
      })
      handleSucces()
      successToast({
        title: 'Phone number validated',
        message: 'The phone number was succesfully validated',
      })
      dispatch(setUser({ mobile: phone }))
    } catch (e) {
      console.error('Error verifying the code', e)
      errorToast({
        title: 'Error validating the code',
        message: 'There was a error verifying the code',
      })
    }
  }

  return (
    <div className='h-full w-full'>
      {!phone ? (
        <PhoneForm
          sendVerificationCode={sendVerificationCode}
          handleBack={handleBack}
          phoneNumber={phoneNumber}
        />
      ) : (
        <CodeForm
          phone={phone}
          validateVerificationCode={validateVerificationCode}
          sendVerificationCode={sendVerificationCode}
        />
      )}
    </div>
  )
}

export default PhoneVerificationForm
