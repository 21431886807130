import { useEffect, useState } from 'react'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import { useUser } from '../../contexts/userContext'
import Button from '@howdy/core/elements/Button'
import Input from '@howdy/core/elements/Input'
import { DropdownRole } from './dropdownRole'
import setCompanyCollaborator from '../../actions/setCompanyCollaborator'
import Loader from '../../components/loader'
import Circle from '@howdy/core/elements/Circle'
import UserPlus from '@howdy/core/elements/Icons/UserPlus'
import useMobile from '../../hooks/useMobile'
import CountrySelect from '@howdy/core/elements/CountrySelect'
import Base from '@howdy/core/elements/Modal/Base'
import ToggleButton from '@howdy/core/elements/ToggleButton'
import { errorToast, successToast, warningToast } from '@howdy/core/lib/toast'

export default function CollaboratorModal({
  display,
  backAction,
  closeAction,
  showLabel,
  isEdit,
  collaborator,
  successAction,
  isFillPaymentInvitation = false,
}) {
  const {
    dispatch,
    userState: { data: user },
  } = useUser()
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      country: 'United States',
    },
  })
  const [isLoading, setIsLoading] = useState(false)
  const [userStatus, setUserStatus] = useState(true)
  const { isMobile } = useMobile()
  useEffect(() => {
    if (collaborator) {
      setValue('role', collaborator.role)
      setValue('firstName', collaborator.firstName)
      setValue('lastName', collaborator.lastName)
      setValue('email', collaborator.email)
      setValue('country', collaborator.country || 'United States')
      setValue('mobile', collaborator.mobile)
      setUserStatus(collaborator.active == 1)
    }
  }, [collaborator])


  const addCollaborator = async (data) => {
    try {
      setIsLoading(true)
      const payload = {
        email: data.email,
        companyId: user.company.id,
        invitedBy: user.id,
        country: data.country,
        role: data.role,
        mobile: data.mobile,
        firstName: data.firstName,
        lastName: data.lastName,
        isFillPaymentInvitation,
      }
      await axios.post('/collaborator', payload)
      successToast({
        title: "Email sent",
        email: "Email successfully sent to collaborator",
      })
      if (successAction) {
        await successAction()
      }
      closeAction()
    } catch (e) {
      warningToast({
        title: e.response?.data?.message || 'Email already used',
        message: e.response?.data?.message || 'Email already used',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const updateCollaborator = async (data) => {
    try {
      setIsLoading(true)
      const payload = {
        id: collaborator.uuid,
        email: data.email,
        country: data.country,
        role: data.role,
        mobile: data.mobile,
        firstName: data.firstName,
        lastName: data.lastName,
        active: userStatus ? 1 : 0,
      }
      await axios.put('/collaborator', payload)
      dispatch(setCompanyCollaborator(payload))
      successToast({
        title: 'Collaborator updated',
        message: 'Collaborator successfully updated',
      })
      if (successAction) {
        await successAction()
      }
      closeAction()
    } catch (e) {
      errorToast({
        title: 'Error updating collaborator',
        message: 'Something went wrong updating the collaborator',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Base isOpen={display} onRequestClose={closeAction}>
      <div className='max-h-[80vh] w-full lg:max-w-[500px]'>
        <div className='flex flex-col items-center '>
          <Circle bgColor='white' borderColor='#F2F2F2' className='text-howdy-primary-green'>
            <UserPlus />
          </Circle>
          <h1 className='mt-3 text-xl font-bold text-howdy-black'>
            {isEdit ? 'Edit' : 'Add'} collaborator
          </h1>
          {isLoading ? (
            <div className='flex h-36 w-full items-center justify-center'>
              <Loader loading={isLoading} />
            </div>
          ) : (
            <>
              <div className='w-full'>
                {showLabel && (
                  <p className='mb-4'>
                    If you <b>don't know the payment information</b> you can invite someone to fill
                    it out for you.
                  </p>
                )}
                <div className='flex flex-col gap-5'>
                  <DropdownRole register={register} control={control} errors={errors} />
                  <div className='flex flex-col gap-x-4 gap-y-5 lg:flex-row'>
                    <Input
                      label='First name'
                      placeholder='Add first name'
                      name='firstName'
                      input={{
                        ...register('firstName', {
                          required: 'Required',
                        }),
                      }}
                      required={true}
                      error={errors && errors['firstName']?.message}
                    />
                    <Input
                      label='Last name'
                      placeholder='Add last name'
                      name='lastName'
                      input={{
                        ...register('lastName'),
                      }}
                      error={errors && errors['lastName']?.message}
                    />
                  </div>
                  <Input
                    label='Email'
                    placeholder='Add email'
                    name='Email'
                    type='email'
                    input={{
                      ...register('email', {
                        required: 'Required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'invalid email address',
                        },
                      }),
                    }}
                    required={true}
                    error={errors && errors['email']?.message}
                  />

                  <div className='flex flex-col gap-x-4 gap-y-5 lg:flex-row'>
                    <div className='flex w-full flex-col lg:w-1/2'>
                      <Controller
                        control={control}
                        name='country'
                        defaultValue='US'
                        render={({ field }) => (
                          <CountrySelect
                            input={{
                              ...field,
                            }}
                            label='Country'
                            required={true}
                          />
                        )}
                      />
                    </div>
                    <div className='w-full lg:w-1/2'>
                      <Input
                        label='Mobile'
                        placeholder='Add mobile'
                        name='mobile'
                        type='text'
                        input={{
                          ...register('mobile', {
                            pattern: {
                              value: /^\+[1-9]\d{10,14}$/,
                              message: 'Invalid number',
                            },
                            maxLength: {
                              value: 14,
                              message: 'Maximum length is 14',
                            },
                          }),
                        }}
                        error={errors && errors['mobile']?.message}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {isEdit && (
                    <div className='mt-4'>
                      <label className='block text-sm  text-howdy-black'>Active</label>
                      <div className='align-center flex py-2'>
                        <label className='pr-4'>No</label>
                        <ToggleButton
                          id={'status'}
                          name={'status'}
                          active={userStatus}
                          onToggle={(value) => {
                            setUserStatus(value)
                          }}
                        />
                        <label className='pl-4'>Yes</label>
                      </div>
                    </div>
                  )}
                </div>

                <div className='mb-5 mt-10 flex w-full flex-col-reverse items-center justify-center gap-5 lg:flex-row'>
                  {backAction && (
                    <div className='hidden lg:block'>
                      <Button
                        style={'tertiary'}
                        type='button'
                        onClick={() => {
                          backAction()
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  )}
                  <Button
                    fullWidth={isMobile}
                    style={'secondary'}
                    type='button'
                    onClick={() => {
                      closeAction()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth={isMobile}
                    onClick={handleSubmit(isEdit ? updateCollaborator : addCollaborator)}
                  >
                    {isEdit ? 'Update' : 'Send'}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Base>
  )
}
