import Button from '@howdy/core/elements/Button'
import Base from '@howdy/core/elements/Modal/Base'
import Input from '@howdy/core/elements/Input'
import SelectInput from '@howdy/core/elements/Select'
import axios from 'axios'
import { useUser } from '../../contexts/userContext'
import { CONTACT_US_CHANNELS } from '../../utils/contactUsChannels'
import { Controller, useForm } from 'react-hook-form'
import Circle from '@howdy/core/elements/Circle'
import PaperPlaneTilt from '@howdy/core/elements/Icons/PaperPlaneTilt'
import useMobile from '../../hooks/useMobile'
import { successToast } from '@howdy/core/lib/toast'

export default function ContactUsModal({ display, closeOnClick, title }) {
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm()

  const {
    userState: { data: user },
  } = useUser()

  const Content = () => {
    return (
      <>
        <div className='w-full'>
          <div className='mb-4 flex w-full flex-col items-center gap-3'>
            <Circle borderColor='#DCDCDC' bgColor='white' className='text-howdy-primary-green'>
              <PaperPlaneTilt />
            </Circle>
            <p className='text-xl font-bold'>Contact Us</p>
          </div>
          <Controller
            control={control}
            name='subject'
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <SelectInput
                  placeholder='Select subject'
                  label='Subject'
                  required={true}
                  error={errors['subject']?.message}
                  options={CONTACT_US_CHANNELS}
                  input={{ ...field }}
                />
              )
            }}
          />
          <Input
            className='mt-4'
            label='Message'
            placeholder='Type your message'
            name='message'
            input={{
              ...register('message', {
                required: 'Required',
              }),
            }}
            textArea
            required={true}
            error={errors && errors['message']?.message}
          />
        </div>
      </>
    )
  }

  const sendMessage = async ({ message, subject }) => {
    await axios.post('/contact-us/send', {
      webhook: subject,
      attachments: [
        {
          color: '#2eb886',
          pretext: 'New request from client',
          author_name: `${user.email} ${user.firstName ? '- ' + user.firstName : ''} ${
            user.lastName || ''
          }`,
          title_link: 'https://api.slack.com/',
          text: message,
          image_url: 'http://my-website.com/path/to/image.jpg',
          thumb_url: 'http://example.com/path/to/thumb.png',
          footer: 'Contact Us App',
          footer_icon: 'https://platform.slack-edge.com/img/default_application_icon.png',
          ts: Date.now(),
        },
      ],
    })
    closeOnClick()
    setValue('message', null)
    setValue('subject', null)
    successToast({
      title: 'Message sent',
      message: 'Your message was succesfully sent.',
    })
  }

  const { isMobile } = useMobile()
  const Footer = () => (
    <div className='flex w-full flex-col-reverse justify-center gap-4 lg:flex-row'>
      <Button fullWidth={isMobile} style={'secondary'} onClick={closeOnClick}>
        Cancel
      </Button>
      <Button fullWidth={isMobile} onClick={handleSubmit(sendMessage)}>
        Send
      </Button>
    </div>
  )

  return (
    <Base isOpen={display} onRequestClose={closeOnClick}>
      <Content />
      <Footer />
    </Base>
  )
}
