import { toast, Toaster, type Toast } from 'react-hot-toast'
import clsx from 'clsx'
import RoundIcon from '../elements/RoundIcon'
import Success from '../elements/Icons/Success'
import Warning from '../elements/Icons/Warning'
import Error from '../elements/Icons/Error'
import Info from '../elements/Icons/Info'
import CloseIcon from '../elements/Icons/CloseIcon'
import Link from 'next/link'

export { Toaster }

const commonProps: Partial<Toast> = {
  duration: 5000,
  position: 'top-right',
}

export const successToast = ({
  duration,
  ...props
}: {
  title: string
  message: string | JSX.Element
  redirectUrl?: string
  duration?: number
}) => {
  return toast.custom((toast) => <Toast {...toast} type={toastTypes.success} {...props} />, {
    ...commonProps,
    ...(duration !== undefined ? { duration } : {}),
  })
}

export const errorToast = ({
  duration,
  ...props
}: {
  title: string
  message: string
  duration?: number
}) => {
  return toast.custom((toast) => <Toast {...toast} type={toastTypes.error} {...props} />, {
    ...commonProps,
    ...(duration !== undefined ? { duration } : {}),
  })
}

export const warningToast = ({
  duration,
  ...props
}: {
  title: string
  message: string
  duration?: number
}) => {
  return toast.custom((toast) => <Toast {...toast} type={toastTypes.warning} {...props} />, {
    ...commonProps,
    ...(duration !== undefined ? { duration } : {}),
  })
}

export const infoToast = ({
  duration,
  ...props
}: {
  title: string
  message: string
  duration?: number
}) => {
  return toast.custom((toast) => <Toast {...toast} type={toastTypes.info} {...props} />, {
    ...commonProps,
    ...(duration !== undefined ? { duration } : {}),
  })
}

export enum toastTypes {
  success,
  warning,
  error,
  info,
}

const icons: Record<toastTypes, React.FC> = {
  [toastTypes.success]: Success,
  [toastTypes.warning]: Warning,
  [toastTypes.error]: Error,
  [toastTypes.info]: Info,
}

const colors = {
  [toastTypes.success]: 'bg-howdy-primary-green',
  [toastTypes.warning]: 'bg-howdy-sunshine',
  [toastTypes.error]: 'bg-[#ED4F00]',
  [toastTypes.info]: 'bg-howdy-dark-blue',
}

function Toast({
  type,
  title,
  message,
  id,
  visible,
  redirectUrl,
}: Toast & {
  type: toastTypes
  title: string
  message: string
  redirectUrl?: string
}) {
  const Icon: React.FC = icons[type]
  return (
    <div
      className={clsx(
        'flex justify-between bg-white transition-all',
        'rounded-lg px-3 py-5 shadow-[4px_4px_8px_0px_rgba(0,0,0,0.10)] md:w-96',
        {
          'animate-in slide-in-from-top': visible,
          '-translate-y-16 opacity-0': !visible,
        },
      )}
    >
      <div className='flex gap-3'>
        <RoundIcon interactive={false} color={colors[type]}>
          <Icon />
        </RoundIcon>
        <div>
          <h3 className='text-lg font-bold'>{title}</h3>
          <span className='text-sm'>{message}</span>
          {redirectUrl && (
            <>
              <br />
              <Link href={redirectUrl}>
                <span className='text-howdy-primary-green underline'>View Cart</span>
              </Link>
            </>
          )}
        </div>
      </div>
      <div className='cursor-pointer' onClick={toast.dismiss.bind(null, id)}>
        <CloseIcon />
      </div>
    </div>
  )
}
