import { useEffect, useState } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { GoogleTagManager } from '@next/third-parties/google'

import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'react-range-slider-input/dist/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'tailwindcss/tailwind.css'
import '../styles/globals.css'
import '../styles/StripeFormStyles.css'
import { useUser, UserProvider } from '../contexts/userContext'

import '../styles/howdy.css'

import setIsLoading from '../actions/setIsLoading'
import setUser from '../actions/setUser'

import '@fortawesome/fontawesome-free/css/all.css'

import { TrackingProvider } from '../contexts/gaTrackerContext'
import { useRouter } from 'next/router'
import Loader from '../components/loader'
import axios from 'axios'
import Head from 'next/head'
import Layout from '@howdy/core/elements/Layout'
import LeadsMenuSections from '@howdy/core/constants/leadsMenuSections'
import ErrorBoundary from '@howdy/core/elements/ErrorBoundary'

import { GoogleOAuthProvider } from '@react-oauth/google'
import keys from '../server/config/keys'
import TutorialVideoModal from '../components/tutorialVideoModal'
import ContactUsModal from '../components/contactUsModal'
import SuperuserMessage from '../components/SuperuserMessage'
import Slides from '../modules/slides'
import AnalyticsScripts from '../components/AnalyticsScripts/AnalyticsScripts'
import NoBillingInfoWarning from '../modules/noBillingInfoWarning'
import { Toaster, successToast } from '@howdy/core/lib/toast'
import RequestAccessModal from '@howdy/core/elements/Modal/requestAccessModal'
import RestrictAccessModal from '@howdy/core/elements/Modal/RestrictAccessModal'
import RequestAccountAccessModal from '@howdy/core/elements/Modal/RequestAccountAccessModal'
import { StoreCartProvider } from '../contexts/storeCartContext'
import { RecommendationsProvider } from '../contexts/recommendationsContext'
import { AuthCommonProvider, useAuthCommon } from '../contexts/authCommonContext'
import MSAuthProvider from '@howdy/howdy-auth-ui/src/lib/msauth'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: ({ queryKey }) => fetch(`/${queryKey.join('/')}`).then((r) => r.json()),
    },
  },
})

const checkRoute = (path) => {
  const unprotectedPathsRegex = [/^\/reset\/.*$/]
  const unprotectedPaths = [
    '/auth',
    '/admin/login',
    '/login',
    '/sign-up',
    '/profile/[id]',
    '/password-recover',
    '/terms-conditions',
    '/help',
    '/zoho-tokens',
    '/oauth2/auth',
    '/my-teams/my-candidates',
    '/my-teams/my-candidates/[code]',
  ]

  const isPublicRoute =
    unprotectedPaths.includes(path) || unprotectedPathsRegex.some((regex) => regex.test(path))

  return { isPublicRoute }
}

const Authenticate = ({ children }) => {
  const { userState: user, dispatch } = useUser()
  const router = useRouter()
  const { updateAuthCommonState } = useAuthCommon()

  const path = router.pathname
  const { isPublicRoute } = checkRoute(path)

  useEffect(() => {
    updateAuthCommonState({
      isAuthenticated: !!user?.data?.id,
      logOut: () => {
        if (window?.signOutUrl) {
          router.push(window.signOutUrl)
        }
      },
    })
  }, [user?.data?.id])

  useEffect(() => {
    const getUser = async () => {
      const queryParams = router.asPath.split('?')[1]
      try {
        dispatch(setIsLoading(true))
        if (user.data.id) return
        const response = await axios.get('/users/getUser')
        if (response.data.user) {
          dispatch(setUser(response.data.user))
        } else {
          if (!isPublicRoute) {
            if (path != '/sign-up' || path != '/login')
              window.location.assign(
                path
                  ? '/sign-up?redirect=' + path + `${queryParams ? '&' + queryParams : ''}`
                  : '/sign-up' + `${queryParams ? '?' + queryParams : ''}`,
              )
          }
        }
      } catch (error) {
        console.error({ error })
        if (!isPublicRoute) {
          if (path != '/login' || path != '/sign-up')
            window.location.assign(
              path
                ? '/sign-up?redirect=' + path + `${queryParams ? '&' + queryParams : ''}`
                : '/sign-up' + `${queryParams ? '?' + queryParams : ''}`,
            )
        }
      } finally {
        dispatch(setIsLoading(false))
      }
    }
    getUser()
  }, [user.data.email, isPublicRoute])

  if (user.isLoading || (!user.hasFetched && !isPublicRoute)) {
    return <Loader loading />
  }

  return (
    <>
      <AnalyticsScripts
        userInfo={user?.data}
        // googleTagId='G-GPCH1TRSNR'
        pendoId='9fe0f08b-9fcf-49cd-725a-70fe2af8e475'
      />
      {process.env.NEXT_PUBLIC_NODE_ENV === 'production' && (
        <GoogleTagManager gtmId='GTM-T8XFP7Q' />
      )}
      {/* <AnalyticsScripts userInfo={user?.data} googleTagId='GTM-T8XFP7Q' /> */}
      {children}
    </>
  )
}

const productionUrls = ['app.howdy.com']

if (typeof window !== 'undefined') {
  window.signOutUrl = '/logout'
  if (productionUrls.includes(window.location.hostname)) {
    const site = 'datadoghq.com'
    const service = 'howdy-app'
    const clientToken = 'pub869e51d774b10775f0083af8b06cd7e2'
    const applicationId = '65327c54-d153-45f1-8041-49e091998f21'

    LogRocket.init('en0wfb/apphowdycom')
    setupLogRocketReact(LogRocket)

    datadogRum.init({
      applicationId,
      clientToken,
      site,
      service,
      // env: 'production',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
    datadogLogs.init({
      site,
      service,
      clientToken,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    })
  }
}

const LayoutWrapper = ({
  children,
  openTutorialVideo,
  openContactUs,
  openAboutUs,
  openRequestAccess,
}) => {
  const router = useRouter()
  const { userState } = useUser()

  useEffect(() => {
    if (
      userState?.data?.role === 'LimitedAccess' &&
      router.pathname !== '/my-teams/my-candidates'
    ) {
      router.replace('/my-teams/my-candidates')
    }
  }, [userState])

  return (
    <Layout
      menuSections={LeadsMenuSections({
        router,
        openTutorialVideo,
        openContactUs,
        openAboutUs,
        openRequestAccess: (url) => {
          openRequestAccess(url, userState)
        },
      })}
      hideMenu={
        router.pathname == '/auth' ||
        router.pathname == '/profile/[id]' ||
        router.pathname == '/login' ||
        router.pathname == '/sign-up' ||
        router.pathname == '/password-recover'
      }
    >
      {children}
    </Layout>
  )
}

function MyApp({ Component, pageProps }) {
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [showContactUs, setShowContactUs] = useState(false)
  const [showSlidesModal, setShowSlidesModal] = useState(false)
  const [showRequestAccessModal, setShowRequestAccessModal] = useState(false)
  const [showRequestAccountAccessModal, setShowRequestAccountAccessModal] = useState(false)
  const [showRestrictModal, setShowRestrictModal] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState(null)
  const [flow, setFlow] = useState('Login')
  const router = useRouter()

  const handleRequestAccess = async (email) => {
    try {
      const { recommendation, company } = router.query
      await axios.post('/accessRequest', {
        sharedProfileId: recommendation,
        role: 'HiringManager',
        email: email,
        companyId: company,
      })
    } catch (error) {
      console.error({ error })
    } finally {
      successToast({
        title: 'Access request sent',
        message: 'Wait until you get access to try again',
      })
      setShowRequestAccessModal(false)
    }
  }

  const handleRequestAccountAccess = async () => {
    try {
      await axios.post('/account-access-request')

      setShowRequestAccountAccessModal(false)
      successToast({
        title: 'Request sent',
        message: (
          <span>
            We sent your request to the account owner. You will be notified by email when the owner
            reviews your request. <br /> Once the request is approved, you must login again.
          </span>
        ),
        duration: Infinity,
      })
    } catch (error) {
      console.error({ error })
    }
  }

  return (
    <GoogleOAuthProvider clientId={keys.googleClientId}>
      <MSAuthProvider clientId={keys.googleClientId}>
        <ErrorBoundary>
          <UserProvider>
            <StoreCartProvider>
              <RecommendationsProvider>
                <AuthCommonProvider>
                  <Authenticate>
                    <TrackingProvider>
                      <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools initialIsOpen={false} />
                        <Head>
                          <title>
                            Howdy | The platform to build and manage your dev team in Latam
                          </title>
                          <meta httpEquiv='Content-Type' content='text/html; charset=UTF-8'></meta>
                          <meta
                            name='keywords'
                            content='Howdy automates the sourcing, vetting, payroll, benefits, and local procurement, all from an easy to use dashboard.'
                          />
                          <meta
                            name='description'
                            content='Howdy automates the sourcing, vetting, payroll, benefits, and local procurement, all from an easy to use dashboard.'
                          ></meta>
                          <meta
                            name='viewport'
                            content='initial-scale=1.0, width=device-width, maximum-scale=1'
                          />
                          <meta name='robots' value='Follow' />
                        </Head>
                        <LayoutWrapper
                          openTutorialVideo={() => setShowVideoModal(true)}
                          openContactUs={() => setShowContactUs(true)}
                          openAboutUs={() => setShowSlidesModal(true)}
                          openRequestAccess={(url, userState) => {
                            if (
                              userState?.viewer?.type == 'Collaborator' ||
                              userState?.viewer?.type == 'Invited'
                            ) {
                              setFlow(
                                userState?.viewer?.type == 'Collaborator' ? 'Login' : 'Signup',
                              )
                              setRedirectUrl(url)
                              setShowRestrictModal(true)
                            } else if (userState?.data?.role === 'LimitedAccess') {
                              setShowRequestAccountAccessModal(true)
                            } else {
                              setShowRequestAccessModal(true)
                            }
                          }}
                        >
                          {router.pathname !== '/auth' &&
                            router.pathname !== '/login' &&
                            router.pathname !== '/sign-up' &&
                            router.pathname !== '/password-recover' && <NoBillingInfoWarning />}
                          <div className='lg:flex lg:h-screen lg:flex-col'>
                            <Component {...pageProps} />
                            <Toaster />
                          </div>
                          <SuperuserMessage />
                        </LayoutWrapper>
                        <TutorialVideoModal
                          display={showVideoModal}
                          closeOnClick={() => setShowVideoModal(false)}
                        />
                        <ContactUsModal
                          title='Contact us'
                          display={showContactUs}
                          closeOnClick={() => setShowContactUs(false)}
                        />
                        <RequestAccessModal
                          isOpen={showRequestAccessModal}
                          onRequestOk={handleRequestAccess}
                          onRequestClose={() => setShowRequestAccessModal(false)}
                        />
                        <RequestAccountAccessModal
                          isOpen={showRequestAccountAccessModal}
                          onRequestOk={handleRequestAccountAccess}
                          onRequestClose={() => setShowRequestAccountAccessModal(false)}
                        />
                        <RestrictAccessModal
                          isOpen={showRestrictModal}
                          redirectUrl={redirectUrl}
                          onRequestClose={() => setShowRestrictModal(false)}
                          onRequestOk={() => setShowRestrictModal(false)}
                          flow={flow}
                        />
                        <Slides
                          display={showSlidesModal}
                          closeAction={() => setShowSlidesModal(false)}
                          flow={'tutorial'}
                        />
                      </QueryClientProvider>
                    </TrackingProvider>
                  </Authenticate>
                </AuthCommonProvider>
              </RecommendationsProvider>
            </StoreCartProvider>
          </UserProvider>
        </ErrorBoundary>
      </MSAuthProvider>
    </GoogleOAuthProvider>
  )
}

export default MyApp
